<template>
  <div
    @click="$emit('showsidebar')"
    class="bg-white shadow rounded p-1 border border-white"
  >
    <div class="d-flex mb-1 justify-content-between align-item-center">
      <!-- <badge v-if="task.type" :color="badgeColor">{{task.type}}</badge> -->
      <b-badge v-if="task.cfirstname" variant="primary" class="badge-glow m-0">{{
        task.cfirstname
      }}</b-badge>
      <b-badge
        v-if="
          task.legal_approval && task.legal_approval.legal_status == task.legal_status
        "
        :variant="approval_status[task.legal_approval.approval_status].color"
        class="badge-glow m-0"
        >{{ approval_status[task.legal_approval.approval_status].name }}</b-badge
      >
      <div v-else></div>
      <!-- <span class="text-sm text-gray-600">{{ task.bookingdate }}</span> -->
    </div>
    <div class="">
      <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm">
        Plot : {{ task.plotno ? task.plotno.plotno : "" }}
      </p>
      <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm">
        Project : {{ task.project ? task.project.projectname : "" }}
      </p>

      <!-- <img
        class="rounded ml-3"
        width="40px"
        height="40px"
        src="https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg"
        alt="Avatar"
      > -->
    </div>
    <div></div>
    <!-- <div class="d-flex justify-content-between align-item-center">
      <div class="d-flex">
        <div class="pr-1 d-flex align-items-end">
          <feather-icon icon="PaperclipIcon" size="18" class="cursor-pointer" />
          <span>2</span>
        </div>
        <div class="pr-1 d-flex align-items-end">
          <feather-icon icon="MessageSquareIcon" size="18" class="cursor-pointer" />
          <span>2</span>
        </div>
      </div>
      <b-avatar-group class="" size="25">
        <b-avatar v-for="avatar in avatars" :key="avatar.avatar" :src="avatar.avatar" class="pull-up" />
        <h6 class="align-self-center cursor-pointer mb-0" style="margin-left:8px">
          +42
        </h6>
      </b-avatar-group>
    </div> -->
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BMediaBody,
  BSidebar,
  VBToggle,
  BMedia,
  BMediaAside,
  BAvatarGroup,
  BAvatar,
  BImg,
  BBadge,
  BLink,
  BFormInput,
} from "bootstrap-vue";
export default {
  components: {
    BBadge,
    BButton,
    BAvatarGroup,
    BAvatar,
  },
  data() {
    return {
      avatars: [
        {
          avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
          fullName: "Billy Hopkins",
        },
        {
          avatar: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
          fullName: "Amy Carson",
        },
        // { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
        // { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
        // { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' },
      ],
      approval_status: {
        0: {
          color: "light-secondary",
          name: "Pending",
        },
        1: {
          color: "light-success",
          name: "Approved",
        },
        2: {
          color: "light-danger",
          name: "Rejected",
        },
      },
    };
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    badgeColor() {
      const mappings = {
        Design: "danger",
        "Feature Request": "primary",
        Backend: "info",
        QA: "success",
        default: "primary",
      };
      // const mappings = {
      //   Design: "light-danger",
      //   "Feature Request": "light-primary",
      //   Backend: "light-info",
      //   QA: "light-success",
      //   default: "light-primary"
      // };
      return mappings[this.task.type] || mappings.default;
    },
  },
};
</script>
<style></style>
