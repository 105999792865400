<template>
  <b-card-code>
    <b-tabs pills v-model="tabSelection">
      <b-tab title="Kanban">
        <div>
          <div>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <label>Project</label>
                  <v-select
                    v-model="project"
                    label="projectname"
                    :options="projectOption"
                    placeholder="None"
                    @input="handelclickproject($event)"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <label>Plot No</label>
                  <v-select
                    v-model="plot"
                    label="plotno"
                    :options="plotOption"
                    placeholder="None"
                    @input="getSalesData"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <label>Search</label>
                <b-form-input
                  v-model="search"
                  placeholder="Search by name or amount"
                  @input="handleSearch"
                />
              </b-col>
            </b-row>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <div class="min-h-screen d-flex py-12" style="overflow-x: auto">
              <div
                v-for="(column, index) in columns"
                :key="index + 1"
                class="column-width removeScroll rounded ml-1 bg-white shadow-lg p-1 overflow-auto position-relative"
                style="height: 80vh"
              >
                <div
                  class="h4 py-2 d-flex justify-content-between position-sticky"
                  style="top: -20px; background-color: white; z-index: 100"
                >
                  <span class="px-1 font-weight-bold">{{ column.title }}</span>
                  <!-- <feather-icon icon="MoreVerticalIcon" size="21" class="cursor-pointer" /> -->
                </div>
                <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
                <draggable
                  :list="column.sales"
                  :animation="200"
                  :class="column.title"
                  ghost-class="ghost-card"
                  group="tasks"
                  :id="index"
                  @end="handleMove($event)"
                  @change="handleChnage($event)"
                >
                  <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
                  <div
                    v-for="sales in column.sales"
                    @mouseenter="handelClickBorder(true, sales.id)"
                    @mouseleave="handelClickBorder(false, sales.id)"
                    :key="sales.id"
                  >
                    <task-card
                      @showsidebar="showSidebar(sales, column)"
                      :handleMove="handleMove"
                      v-if="sales.id"
                      :task="sales"
                      class="mb-1 cursor-move"
                      :class="
                        borderId[sales.id]
                          ? 'border-primary border-lighten-2'
                          : 'border-primary border-lighten-5'
                      "
                    ></task-card>
                  </div>
                  <!-- </transition-group> -->
                </draggable>
              </div>
              <!-- <b-sidebar
                    title="Update"
                    :visible="active"
                    bg-variant="white"
                    width="400px"
                    right
                    backdrop
                    shadow
                  >
                    <sidebar-content />
                  </b-sidebar> -->
              <b-modal
                style="min-width: 990px !important"
                v-model="active"
                centered
                title="NG-454"
                ok-only
                size="jira"
                ok-title="Close"
                title-class="font-weight-300"
              >
                <b-row>
                  <b-col md="7" class="p-md-1">
                    <b-form-group>
                      <label class="font-weight-300 mt-1">Attachment</label>
                      <b-row>
                        <b-col cols="10" class="pr-0">
                          <b-form-file
                            v-model="document"
                            placeholder="Select Photo"
                            drop-placeholder="Drop file here..."
                            accept="image/*"
                            @input="handleFile($event, 'attachment', 'legal')"
                            hide-input
                          >
                          </b-form-file>
                          <attachment :data="document" />
                        </b-col>
                        <b-col cols="2" class="p-0 text-center">
                          <b-button
                            variant="primary"
                            style="
                              border-radius: 50%;
                              height: 40px;
                              width: 40px;
                              padding: 0px;
                            "
                            @click="handleCommentAttachment"
                            >➤</b-button
                          >
                        </b-col>
                      </b-row>
                    </b-form-group>
                    <b-row class="m-1" style="max-height: 250px; overflow-x: auto">
                      <div class="d-flex">
                        <div
                          class="multiAtt"
                          style="height: 150px; width: 150px; margin-right: 10px"
                          v-for="(data, index) in documents"
                          :key="index"
                        >
                          <attachment class="attechmentDiv" :data="data.comments" />
                        </div>
                      </div>
                    </b-row>
                    <div>
                      <b-tabs pills>
                        <b-tab
                          active
                          style="
                            height: 380px;
                            padding: 2px;
                            overflow: auto;
                            overflow-x: hidden;
                          "
                        >
                          <template #title> Comments </template>
                          <b-row class="pl-1">
                            <b-col class="p-0" cols="2">
                              <b-avatar variant="primary" text="PI" />
                            </b-col>
                            <b-col class="pl-0 pr-0" cols="9">
                              <b-form-group>
                                <b-form-input
                                  placeholder="Add a Comment"
                                  class=""
                                  v-model="comment"
                                />
                              </b-form-group>
                              <div v-if="comment">
                                <b-button
                                  variant="primary"
                                  type="submit"
                                  size="sm"
                                  @click.prevent="submitComment"
                                >
                                  Submit
                                </b-button>
                                <b-button
                                  variant="primary"
                                  class="ml-1"
                                  size="sm"
                                  @click="comment = null"
                                  >Cancel
                                </b-button>
                              </div>
                            </b-col>
                            <!-- <b-col class="p-0 text-center" cols="2">
                              <b-avatar variant="primary" class="cursor-pointer" text="➤" />
                            </b-col> -->
                          </b-row>
                          <b-row v-if="comments.length == 0" class="w-100">
                            <b-col md="12 mt-1">
                              <h4 class="text-center">No Comments</h4>
                            </b-col>
                          </b-row>
                          <b-row
                            class="mt-2"
                            v-for="(data, index) in comments"
                            :key="index"
                          >
                            <b-col style="" cols="2" v-if="data.enterby">
                              <b-img
                                style="border-radius: 50% !important"
                                ref="previewEl"
                                rounded
                                :src="data.enterby[0].profile_image"
                                height="40"
                                width="40"
                              />
                            </b-col>
                            <b-col class="pl-1" cols="10">
                              <b-row>
                                <b-col
                                  class="pl-0 d-flex align-items-center justify-content-between"
                                  cols="12"
                                >
                                  <div class="d-flex justify-content-between w-100 ml-1">
                                    <span class="pr-2 h6"
                                      >{{ data.enterby ? data.enterby[0].surname : "" }}
                                      {{
                                        data.enterby ? data.enterby[0].name : ""
                                      }} </span
                                    ><span>
                                      <b-badge variant="primary" class="badge-glow m-0">{{
                                        data.enterdatetime
                                          ? data.enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .join("/")
                                          : ""
                                      }}</b-badge>
                                    </span>
                                  </div>
                                </b-col>
                                <p class="pt-1 ml-1">{{ data.comments }}</p>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-tab>
                        <b-tab style="max-height: 380px; overflow-y: auto; padding: 10px">
                          <template #title> Timeline </template>
                          <b-row>
                            <app-timeline class="w-100">
                              <!-- <app-timeline-item variant="info" :fillBorder="true">
                                <div class="mainBox">
                                  <div>Entry</div>
                                  <div class="timelineBox">
                                    <div class="mb-1">
                                      Approved By : Admin
                                    </div>
                                    <div>
                                      Time Taken : 25 Days
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item> -->
                              <app-timeline-item
                                variant="danger"
                                :fillBorder="
                                  timeline[1] && timeline[1].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>BanaChhiththi Draft</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[1]"
                                      >{{
                                        timeline[1].enterdatetime
                                          ? timeline[1].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[1].enterdatetime
                                          ? timeline[1].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[1]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[1].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[1] ? timeline[1].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[1]">
                                    <div v-if="timeline[1].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[1].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[1].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[1].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[1].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[1].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="warning"
                                :fillBorder="
                                  timeline[2] && timeline[2].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Title Form For Signature of Farmer</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[2]"
                                      >{{
                                        timeline[2].enterdatetime
                                          ? timeline[2].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[2].enterdatetime
                                          ? timeline[2].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[2]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[2].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[2] ? timeline[2].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[2]">
                                    <div v-if="timeline[2].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[2].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[2].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[2].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[2].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[2].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="primary"
                                :fillBorder="
                                  timeline[3] && timeline[3].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Security Report</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[3]"
                                      >{{
                                        timeline[3].enterdatetime
                                          ? timeline[3].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[3].enterdatetime
                                          ? timeline[3].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[3]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[3].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[3] ? timeline[3].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[3]">
                                    <div v-if="timeline[3].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[3].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[3].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[3].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[3].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[3].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="success"
                                :fillBorder="
                                  timeline[4] && timeline[4].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Publication in News Paper</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[4]"
                                      >{{
                                        timeline[4].enterdatetime
                                          ? timeline[4].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[4].enterdatetime
                                          ? timeline[4].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[4]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[4].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                  </div>
                                  <div class="ml-1">
                                    {{ timeline[4] ? timeline[4].remarks : "" }}
                                  </div>
                                  <div class="timelineBox" v-if="timeline[4]">
                                    <div v-if="timeline[4].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[4].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[4].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[4].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[4].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[4].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="info"
                                :fillBorder="
                                  timeline[5] && timeline[5].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Revenue Records Documents</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[5]"
                                      >{{
                                        timeline[5].enterdatetime
                                          ? timeline[5].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[5].enterdatetime
                                          ? timeline[5].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[5]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[5].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[5] ? timeline[5].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[5]">
                                    <div v-if="timeline[5].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[5].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[5].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[5].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[5].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[5].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="danger"
                                :fillBorder="
                                  timeline[6] && timeline[6].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Agreement to Sale</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[6]"
                                      >{{
                                        timeline[6].enterdatetime
                                          ? timeline[6].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[6].enterdatetime
                                          ? timeline[6].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[6]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[6].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                  </div>
                                  <div class="ml-1">
                                    {{ timeline[6] ? timeline[6].remarks : "" }}
                                  </div>
                                  <div class="timelineBox" v-if="timeline[6]">
                                    <div v-if="timeline[6].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[6].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[6].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[6].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[6].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[6].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="warning"
                                :fillBorder="
                                  timeline[7] && timeline[7].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Submission of NA Application</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[7]"
                                      >{{
                                        timeline[7].enterdatetime
                                          ? timeline[7].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[7].enterdatetime
                                          ? timeline[7].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[7]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[7].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                  </div>
                                  <div class="ml-1">
                                    {{ timeline[7] ? timeline[7].remarks : "" }}
                                  </div>
                                  <div class="timelineBox" v-if="timeline[7]">
                                    <div v-if="timeline[7].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[7].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[7].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[7].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[7].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[7].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="primary"
                                :fillBorder="
                                  timeline[8] && timeline[8].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Title Report-Final</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[8]"
                                      >{{
                                        timeline[8].enterdatetime
                                          ? timeline[8].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[8].enterdatetime
                                          ? timeline[8].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[8]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[8].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[8] ? timeline[8].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[8]">
                                    <div v-if="timeline[8].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[8].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[8].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[8].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[8].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[8].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="success"
                                :fillBorder="
                                  timeline[9] && timeline[9].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Doc -> Query of Title Report</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[9]"
                                      >{{
                                        timeline[9].enterdatetime
                                          ? timeline[9].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[9].enterdatetime
                                          ? timeline[9].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[9]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[9].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[9] ? timeline[9].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[9]">
                                    <div v-if="timeline[9].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[9].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[9].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[9].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[9].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[9].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="info"
                                :fillBorder="
                                  timeline[10] && timeline[10].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Id of Buyer for Appointment Schedule</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[10]"
                                      >{{
                                        timeline[10].enterdatetime
                                          ? timeline[10].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[10].enterdatetime
                                          ? timeline[10].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[10]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[10].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[10] ? timeline[10].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[10]">
                                    <div v-if="timeline[10].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[10].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[10].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[10].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[10].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[10].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="danger"
                                :fillBorder="
                                  timeline[11] && timeline[11].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Sale Deed for Execution </span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[11]"
                                      >{{
                                        timeline[11].enterdatetime
                                          ? timeline[11].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[11].enterdatetime
                                          ? timeline[11].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[11]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[11].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[11] ? timeline[11].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[11]">
                                    <div v-if="timeline[11].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[11].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[11].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[11].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[11].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[11].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div> </app-timeline-item
                              ><app-timeline-item
                                variant="warning"
                                :fillBorder="
                                  timeline[12] && timeline[12].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>POA (Power of Attorney)</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[12]"
                                      >{{
                                        timeline[12].enterdatetime
                                          ? timeline[12].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[12].enterdatetime
                                          ? timeline[12].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[12]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[12].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[12] ? timeline[12].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[12]">
                                    <div v-if="timeline[12].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[12].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[12].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[12].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[12].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[12].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div> </app-timeline-item
                              ><app-timeline-item
                                variant="primary"
                                :fillBorder="
                                  timeline[13] && timeline[13].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Uploading of Executed Docs</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[13]"
                                      >{{
                                        timeline[13].enterdatetime
                                          ? timeline[13].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[13].enterdatetime
                                          ? timeline[13].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[13]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[13].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[13] ? timeline[13].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[13]">
                                    <div v-if="timeline[13].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[13].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[13].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[13].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[13].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[13].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="success"
                                :fillBorder="
                                  timeline[14] && timeline[14].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span>Citation of Entries</span>
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[14]"
                                      >{{
                                        timeline[14].enterdatetime
                                          ? timeline[14].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[14].enterdatetime
                                          ? timeline[14].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[14]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[14].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[14] ? timeline[14].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[14]">
                                    <div v-if="timeline[14].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[14].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[14].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[14].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[14].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[14].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                              <app-timeline-item
                                variant="info"
                                :fillBorder="
                                  timeline[15] && timeline[15].remarks ? true : false
                                "
                              >
                                <div class="mainBox">
                                  <div
                                    class="d-flex flex-wrap justify-content-between w-100"
                                  >
                                    <span
                                      >Confirmation of Buyer (for received
                                      documents)</span
                                    >
                                    <b-badge
                                      variant="primary"
                                      class="badge-glow mt-1"
                                      v-if="timeline[15]"
                                      >{{
                                        timeline[15].enterdatetime
                                          ? timeline[15].enterdatetime
                                              .split("T")[0]
                                              .split("-")
                                              .reverse()
                                              .join("/")
                                          : ""
                                      }}
                                      {{
                                        timeline[15].enterdatetime
                                          ? timeline[15].enterdatetime
                                              .split("T")[1]
                                              .split(".")[0]
                                          : ""
                                      }}</b-badge
                                    >
                                  </div>
                                  <div
                                    v-if="timeline[15]"
                                    class="d-flex justify-content-start mt-1"
                                  >
                                    <b-img
                                      style="border-radius: 50% !important"
                                      rounded
                                      :src="timeline[15].enterby.profile_image"
                                      height="40"
                                      width="40"
                                    />
                                    <div class="ml-1">
                                      {{ timeline[15] ? timeline[15].remarks : "" }}
                                    </div>
                                  </div>
                                  <div class="timelineBox" v-if="timeline[15]">
                                    <div v-if="timeline[15].attachment1">
                                      <div class="mr-1">Attachment 1</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[15].attachment1"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[15].attachment2">
                                      <div class="mr-1">Attachment 2</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[15].attachment2"
                                        />
                                      </div>
                                    </div>
                                    <div v-if="timeline[15].attachment3">
                                      <div class="mr-1">Attachment 3</div>
                                      <div>
                                        <attachment
                                          class="attechmentDiv"
                                          :data="timeline[15].attachment3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </app-timeline-item>
                            </app-timeline>
                          </b-row>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </b-col>
                  <b-col md="5" class="overflow-auto mt-2">
                    <div>
                      <b-badge variant="primary" class="badge-glow m-0">{{
                        selected_column.title
                      }}</b-badge>
                      <p class="mt-1">To</p>
                      <v-select
                        style="width: 300px"
                        v-model="selected_column"
                        label="title"
                        :options="columns"
                        placeholder="None"
                        @input="moveFromDropdown($event)"
                      />
                    </div>
                    <div class="mt-1 detailsForm">
                      <div class="p-1 detailHeader">
                        <h4>Details</h4>
                      </div>
                      <div class="d-flex flex-wrap p-1">
                        <div class="w-50 mt-1 font-weight-bolder">Project :</div>
                        <div class="w-50 mt-1">
                          {{ sales.project ? sales.project.projectname : "" }}
                        </div>
                        <div class="w-50 mt-1 font-weight-bolder">Plot No :</div>
                        <div class="w-50 mt-1">
                          {{ sales.plotno ? sales.plotno.plotno : "" }}
                        </div>
                        <div class="w-50 mt-1 font-weight-bolder">Customer :</div>
                        <div class="w-50 mt-1 font-weight-bolder text-primary">
                          {{ sales.cfirstname }}
                        </div>
                        <div class="w-50 mt-1 font-weight-bolder">Sales By:</div>
                        <div class="w-50 mt-1 font-weight-bolder text-primary">
                          {{ sales.team ? sales.team.name : "" }}
                        </div>
                        <div class="w-50 mt-1 font-weight-bolder">
                          Plot Area (in Sq. Yd)
                        </div>
                        <div class="w-50 mt-1">
                          {{ sales.plotno ? sales.plotno.plotareayd : "" }}
                        </div>
                        <div class="w-50 mt-1 font-weight-bolder">
                          Plot rate (Per Sq. Yd)
                        </div>
                        <div class="w-50 mt-1">
                          {{ sales.plotrate }}
                        </div>
                        <div class="w-50 mt-1 font-weight-bolder">Plot Value</div>
                        <div class="w-50 mt-1">
                          {{ sales.plotvalue }}
                        </div>
                        <div class="w-50 mt-1 font-weight-bolder">Total Amount</div>
                        <div class="w-50 mt-1">
                          {{ sales.totalpayment }}
                        </div>
                        <div class="w-50 mt-1 font-weight-bolder">Booking Date</div>
                        <div class="w-50 mt-1">
                          {{
                            sales.bookingdate
                              ? sales.bookingdate.split("-").reverse().join("/")
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-modal>
              <b-modal
                @hidden="handleCancel"
                v-model="showRemarkPopUp"
                centered
                title="Documents"
                hide-footer
                ok-title="Save"
                title-class="font-weight-300"
              >
                <b-row>
                  <b-col md="12" class="mb-2">
                    <div class="">
                      <div class="mt-1 d-flex justify-content-start">
                        <div style="width: 11%" class="font-weight-bolder">From:</div>
                        <b-badge
                          variant="danger"
                          style="height: 26px !important; font-size: 14px"
                          class="m-0"
                          >{{ fromTitle }}
                        </b-badge>
                      </div>
                      <div class="mt-1 d-flex justify-content-start">
                        <div style="width: 11%" class="font-weight-bolder">To:</div>
                        <b-badge
                          variant="success"
                          style="height: 26px !important; font-size: 14px"
                          class="m-0"
                          >{{ toTitle }}
                        </b-badge>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="12">
                    <div class="w-100 remarksDetails">
                      <div class="d-flex justify-content-between w-100">
                        <span class="w-50 font-weight-bolder">Client Name :</span>
                        <span class="w-50">{{ sales.cfirstname }}</span>
                      </div>
                      <div class="d-flex mt-1 justify-content-between w-100">
                        <span class="w-50 font-weight-bolder">Project Name : </span
                        ><span class="w-50">{{
                          sales.project && sales.project ? sales.project.projectname : ""
                        }}</span>
                      </div>
                      <div class="d-flex mt-1 justify-content-between w-100">
                        <span class="w-50 font-weight-bolder">Plot No. : </span
                        ><span class="w-50">{{
                          sales.plotno && sales.plotno ? sales.plotno.plotno : ""
                        }}</span>
                      </div>
                      <div class="d-flex mt-1 justify-content-between w-100">
                        <span class="w-50 font-weight-bolder">Sales By : </span
                        ><span class="w-50">{{
                          sales.team && sales.team ? sales.team.fullname : ""
                        }}</span>
                      </div>
                    </div>
                  </b-col>
                  <!-- <b-col md="12">
                    <b-form-group>
                      <label>Datetime</label>
                      <flat-pickr v-model="datetime" class="form-control" :config="{
                        dateFormat: 'd/m/Y H:i ',
                        enableTime: true
                      }" />
                    </b-form-group>
                  </b-col> -->
                  <b-col>
                    <b-row>
                      <validation-observer ref="simpleRules">
                        <b-col md="12" class="mt-2">
                          <b-form-group>
                            <label>Attachment 1</label>
                            <b-form-file
                              v-model="attachments.att1"
                              placeholder="Select Document"
                              drop-placeholder="Drop file here..."
                              accept="image/*"
                              @input="handleFile($event, 'att1', 'saleslegal')"
                            />
                          </b-form-group>
                          <attachment :data="attachments.att1" />
                        </b-col>
                        <b-col md="12">
                          <b-form-group>
                            <label>Attachment 2</label>
                            <b-form-file
                              v-model="attachments.att2"
                              placeholder="Select Document"
                              drop-placeholder="Drop file here..."
                              accept="image/*"
                              @input="handleFile($event, 'att2', 'saleslegal')"
                            />
                          </b-form-group>
                          <attachment :data="attachments.att2" />
                        </b-col>
                        <b-col md="12">
                          <b-form-group>
                            <label>Attachment 3</label>
                            <b-form-file
                              v-model="attachments.att3"
                              placeholder="Select Document"
                              drop-placeholder="Drop file here..."
                              accept="image/*"
                              @input="handleFile($event, 'att3', 'saleslegal')"
                            />
                          </b-form-group>
                          <attachment :data="attachments.att3" />
                        </b-col>
                        <b-col md="12">
                          <b-form-group>
                            <label>Remarks</label>
                            <label style="color: red !important">*</label>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="Remarks"
                            >
                              <b-form-textarea
                                v-model="remarks"
                                placeholder="Enter Remarks"
                                rows="5"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col cols="12">
                          <b-button variant="primary" @click="handleCancel"
                            >Cancel
                          </b-button>
                          <b-button
                            variant="primary"
                            type="submit"
                            :disabled="submitDisable"
                            @click.prevent="submitRemarks"
                            class="ml-1"
                          >
                            Submit
                          </b-button>
                        </b-col>
                      </validation-observer>
                    </b-row>
                  </b-col>
                </b-row>
              </b-modal>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Approvals">
        <approval-list v-if="tabSelection == 1"></approval-list>
      </b-tab>
      <b-tab title="Registered Plots">
        <plot-list v-if="tabSelection == 2"></plot-list>
      </b-tab>
    </b-tabs>
  </b-card-code>
</template>

<script>
import draggable from "vuedraggable";
import TaskCard from "./TaskCard.vue";
import Ripple from "vue-ripple-directive";
import Attachment from "../../../components/Attechment.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import _ from "lodash";
import vSelect from "vue-select";
import BCardCode from "@/@core/components/b-card-code/BCardCode.vue";
import ApprovalList from "./ApprovalList.vue";
import PlotList from "./PlotList.vue";

import axios from "@/components/axios";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BMediaBody,
  BSidebar,
  BRow,
  BCol,
  BFormFile,
  VBToggle,
  BMedia,
  BMediaAside,
  BAvatar,
  BImg,
  BBadge,
  BLink,
  BFormInput,
  BFormGroup,
  BTabs,
  BTab,
  BFormTextarea,
} from "bootstrap-vue";
import SidebarContent from "./SidebarContent.vue";
// import columns from './data.json'
export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    TaskCard,
    SidebarContent,
    Attachment,
    BFormGroup,
    BSidebar,
    draggable,
    BCardCode,
    BFormInput,
    BFormFile,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButton,
    BTabs,
    BTab,
    flatPickr,
    BFormTextarea,
    BImg,
    ApprovalList,
    PlotList,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      active: false,
      borderId: {},
      tabSelection: 0,
      project: "",
      projectOption: [],
      plot: "",
      plotOption: [],
      test: [{ data: "test" }, { data: "img" }],
      datetime: "",
      attachments: {
        att1: "",
        att2: "",
        att3: "",
      },
      fromTitle: "",
      toTitle: "",
      fromId: "",
      toId: "",
      documents: [],
      document: null,

      comments: [],
      comment: "",
      showRemarkPopUp: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      loginUser: localStorage.getItem("userData"),
      sales: {},
      selected_column: "",
      columns: [
        {
          title: "Entry",
          sales: [],
          id: 0,
        },
        {
          title: "BanaChhiththi Draft",
          sales: [],
          id: 1,
        },
        {
          title: "Title Form For Signature of Farmer",
          sales: [],
          id: 2,
        },
        {
          title: "Security Report",
          sales: [],
          id: 3,
        },
        {
          title: "Publication in News Paper",
          sales: [],
          id: 4,
        },
        {
          title: "Revenue Records Documents",
          sales: [],
          id: 5,
        },
        {
          title: "Agreement to Sale",
          sales: [],
          id: 6,
        },
        {
          title: "Submission of NA Application",
          sales: [],
          id: 7,
        },
        {
          title: "Title Report-Final",
          sales: [],
          id: 8,
        },
        {
          title: "Doc -> Query of Title Report",
          sales: [],
          id: 9,
        },
        {
          title: "Id of Buyer for Appointment Schedule",
          sales: [],
          id: 10,
        },
        {
          title: "Sale Deed for Execution",
          sales: [],
          id: 11,
        },
        {
          title: "POA (Power of Attorney)",
          sales: [],
          id: 12,
        },
        {
          title: "Uploading of Executed Docs",
          sales: [],
          id: 13,
        },
        {
          title: "Citation of Entries",
          sales: [],
          id: 14,
        },
        {
          title: "Confirmation of Buyer (for received documents)",
          sales: [],
          id: 15,
        },
      ],
      submitDisable: false,
      remarks: "",
      timeline: {},
      search: "",
      previous: 0,

      approval_status: {
        0: {
          color: "light-secondary",
          name: "Pending",
        },
        1: {
          color: "light-success",
          name: "Approved",
        },
        2: {
          color: "light-danger",
          name: "Rejected",
        },
      },
    };
  },
  mounted() {
    this.getSalesData();
    this.getProjects();
  },
  computed: {
    badgeColor() {
      const mappings = {
        Design: "danger",
        "Feature Request": "primary",
        Backend: "info",
        QA: "success",
        default: "primary",
      };
      return mappings[this.task.type] || mappings.default;
    },
  },
  methods: {
    async moveFromDropdown(evt) {
      this.attachments = {
        att1: null,
        att2: null,
        att2: null,
      };
      this.remarks = null;
      // this.datetime = null
      this.toTitle = evt.title;
      this.fromTitle = this.previous.title;
      this.fromId = parseInt(this.previous.id);

      this.toId = parseInt(evt.id);

      this.showRemarkPopUp = true;
    },
    handleSearch: _.debounce(function () {
      this.getSalesData();
    }, 1000),
    async getProjects() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handelclickproject() {
      this.getSalesData();
      this.getPlot();
    },
    async getPlot() {
      if (this.project && this.project.id) {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getallplotbyproject/${this.project.id}`,
        };
        await axios(requestOptions)
          .then((response) => {
            this.plotOption = response.data.data;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async handleCommentAttachment() {
      if (this.document) {
        let data = {
          comments: this.document,
          user: this.loginUser.id,
          type: "sales_attachment",
          main_id: this.sales.id,
        };
        const requestoption = {
          method: "POST",
          headers: {
            "Contant-type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: data,
          url: `${this.baseApi}/legal-comment`,
        };
        await axios(requestoption).then((response) => {
          this.document = null;
          this.getCommentsAttachment();
        });
      }
    },
    async getCommentsAttachment() {
      let data = {
        type: "sales_attachment",
        main_id: this.sales.id,
      };
      const requestoption = {
        method: "POST",
        headers: {
          "Contant-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: data,
        url: `${this.baseApi}/legal-all-comments`,
      };
      await axios(requestoption).then((response) => {
        this.documents = response.data.data;
      });
    },
    handleChnage(evt) {
      if (evt && evt.removed) {
        this.sales = evt.removed.element;
      }
    },
    async submitComment() {
      if (this.comment) {
        let data = {
          comments: this.comment,
          user: this.loginUser.id,
          type: "sales",
          main_id: this.sales.id,
        };
        const requestoption = {
          method: "POST",
          headers: {
            "Contant-type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: data,
          url: `${this.baseApi}/legal-comment`,
        };
        await axios(requestoption).then((response) => {
          this.comment = "";
          this.getComments();
        });
      }
    },
    async getComments() {
      let data = {
        type: "sales",
        main_id: this.sales.id,
      };
      const requestoption = {
        method: "POST",
        headers: {
          "Contant-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: data,
        url: `${this.baseApi}/legal-all-comments`,
      };
      await axios(requestoption).then((response) => {
        this.comments = response.data.data;
      });
    },
    handleCancel() {
      this.attachments = {
        att1: null,
        att2: null,
        att2: null,
      };
      this.remarks = null;
      // this.datetime = null
      this.showRemarkPopUp = false;
      this.getSalesData();
    },
    async submitRemarks() {
      const data = {
        // datetime: this.datetime,
        attachment1: this.attachments.att1,
        attachment2: this.attachments.att2,
        attachment3: this.attachments.att3,
        type: "sales",
        main_id: this.sales.id,
        remarks: this.remarks,
        status: this.toId,
        is_add: 1,
      };
      this.$refs.simpleRules.validate().then(async (success) => {
        if (
          success &&
          (this.attachments.att1 || this.attachments.att2 || this.attachments.att3)
        ) {
          const requestoption = {
            method: "POST",
            headers: {
              "Contant-type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: data,
            url: `${this.baseApi}/legal`,
          };
          await axios(requestoption)
            .then((response) => {
              this.showRemarkPopUp = false;
              this.$swal({
                title: "Success",
                timer: 5000,
                text: "Documents successfully saved",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              console.log(error.response, "error");
              this.$swal({
                title: "Error",
                timer: 5000,
                text: error.response.data.message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .finally(() => {
              this.getSalesData();
            });
        } else {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill Remarks And Atleast One Attachment",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },

    handleMove(evt) {
      if (evt && parseInt(evt.from.id) < parseInt(evt.to.id)) {
        this.attachments = {
          att1: null,
          att2: null,
          att2: null,
        };
        this.remarks = null;
        // this.datetime = null
        this.toTitle = evt.to.className;
        this.fromTitle = evt.from.className;
        this.fromId = parseInt(evt.from.id);

        this.toId = parseInt(evt.to.id);

        this.showRemarkPopUp = true;
      } else if (evt) {
        this.fromId = parseInt(evt.from.id);
        this.toId = parseInt(evt.to.id);

        this.updateSalesNew();
      }
    },
    async updateSalesNew() {
      let data = {
        main_id: this.sales.id,
        status: this.toId,
        type: "sales",
        is_add: 0,
      };
      const requestoption = {
        method: "POST",
        headers: {
          "Contant-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: data,
        url: `${this.baseApi}/legal`,
      };
      await axios(requestoption)
        .then((response) => {
          this.getSalesData();
        })
        .catch((error) => {
          console.log(error.response, "error");
        });
    },
    async getSalesData() {
      let params = {
        project: this.project ? this.project.id : "",
        plot: this.plot ? this.plot.id : "",
        search: this.search,
      };
      const requestoption = {
        method: "GET",
        headers: {
          "Contant-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/get-sales-by-legal-status`,
        params: params,
      };
      await axios(requestoption)
        .then((response) => {
          Object.keys(response.data.data).map((item, index) => {
            if (index < 16) {
              this.columns[item].sales = response.data.data[item];
            }
          });
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    showSidebar(sales, column) {
      this.active = !this.active;
      this.sales = sales;
      this.selected_column = column;
      this.previous = column;
      this.comments = [];
      this.timeline = {};
      this.documents = [];
      this.document = null;
      this.getCommentsAttachment();
      this.$forceUpdate();
      this.getComments();
      this.getAttachments();
    },
    handelClickBorder(status, id) {
      this.borderId[id] = status;
      this.$forceUpdate();
    },
    async getAttachments() {
      this.timeline = {};

      const data = {
        main_id: this.sales.id,
        type: "sales",
      };
      const requestoption = {
        method: "POST",
        headers: {
          "Contant-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: data,
        url: `${this.baseApi}/get-all-attachment`,
      };
      await axios(requestoption)
        .then((response) => {
          response.data.data.map((item) => {
            this.timeline[item.status] = item;
          });
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error, "error");
        });
    },
    async handleFile(e, name, type) {
      this.submitDisable = true;

      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestoption = {
        method: "POST",
        headers: {
          "Contant-type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestoption)
        .then((response) => {
          if (type == "saleslegal") {
            this.attachments[name] =
              process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else {
            this.document = response.data.attachment;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(() => {
          this.submitDisable = false;
        });
    },
  },
};
</script>

<style scoped>
.attechmentDiv {
  display: flex;
  max-height: 200px;
  max-width: 200px;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.column-width {
  min-width: 250px;
  max-width: 250px;
}

/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}

.removeScroll::-webkit-scrollbar {
  display: none;
}

.detailsForm {
  border: 1px solid #d8d6de;
}

.detailHeader {
  border-bottom: 1px solid #d8d6de;
}

.boxBorder {
  border: 1px solid #d8d6de;
}

.timelineBox {
  padding: 20px;
  display: flex;
}

.mainBox {
  padding: 10px;
  font-weight: bolder;
  min-height: 70px;
  width: 100%;
}

.remarksDetails {
  padding: 20px;
  border: 1px solid #d8d6de;
  border-radius: 10px;
}

.sendButton {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.441);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.941);
}
</style>
<style>
@media (min-width: 992px) {
  .modal-jira {
    min-width: 990px;
    min-height: 90%;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .modal-jira {
    max-width: 700px;
  }
}
</style>
